require('./plugins/contentful-paint');

document.addEventListener('DOMContentLoaded', function () {
    // common
    require('./common');

    // if (document.querySelector('main.template-home')) {
    //     import('./pages/home.js');
    // }
    // if (document.querySelector('main.template-page-about')) {
    //     import('./pages/about.js');
    // }
    // if (document.querySelector('main.template-single-program:not(.template-single-program-landing)')) {
    //     import('./pages/single-program.js');
    // }
    // if (document.querySelector('main.template-single-program-landing')) {
    //     import('./pages/single-program-landing.js');
    // }
    // if (document.querySelector('main.template-single-speakers')) {
    //     import('./pages/single-speaker.js');
    // }
    // if (document.querySelector('main.template-page-insights')) {
    //     import('./pages/insights.js');
    // }
});
